@font-face {
  font-family: 'museo300';
  src: url("../fonts/museo300-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/museo300-regular-webfont.woff2") format("woff2"), url("../fonts/museo300-regular-webfont.woff") format("woff"), url("../fonts/museo300-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'museo500';
  src: url("../fonts/museo500-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/museo500-regular-webfont.woff2") format("woff2"), url("../fonts/museo500-regular-webfont.woff") format("woff"), url("../fonts/museo500-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'museo700';
  src: url("../fonts/museo700-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/museo700-regular-webfont.woff2") format("woff2"), url("../fonts/museo700-regular-webfont.woff") format("woff"), url("../fonts/museo700-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'svg-icon';
  src: url("../fonts/svg-icon.eot?6tg7se#iefix") format("embedded-opentype"), url("../fonts/svg-icon.ttf?6tg7se") format("truetype"), url("../fonts/svg-icon.woff?6tg7se") format("woff"), url("../fonts/svg-icon.svg?6tg7se#svg-icon") format("svg");
  font-weight: normal;
  font-style: normal; }
.icon-font {
  font-family: 'svg-icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-car:before {
  content: "\e800"; }

.icon-card:before {
  content: "\e801"; }

.icon-clock:before {
  content: "\e802"; }

.icon-document:before {
  content: "\e803"; }

.icon-driver:before {
  content: "\e804"; }

.icon-vehicleDriver:before {
  content: "\e805"; }

a:focus {
  outline: none; }

.inner {
  padding: 0 20px; }

.title-application {
  overflow: hidden;
  margin: 0;
  font-size: 1.25rem;
  color: white;
  text-align: right;
  text-transform: capitalize; }
  .title-application span {
    display: none; }

.panel-group {
  background: white; }
  .panel-group .panel {
    box-shadow: none; }
  .panel-group .panel.panel-default {
    border: none;
    margin-top: 2px; }
    .panel-group .panel.panel-default:first-child {
      margin-top: 0; }
    .panel-group .panel.panel-default .panel-heading {
      padding: 0; }
  .panel-group .panel-title-inner {
    display: block;
    padding: 6px 45px 6px 6px;
    background: #86a8cd;
    font-family: "museo500";
    color: white;
    font-size: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative; }
    .panel-group .panel-title-inner:hover, .panel-group .panel-title-inner:focus {
      text-decoration: none; }
    .panel-group .panel-title-inner .number {
      display: inline-block;
      width: 31px;
      height: 31px;
      border-radius: 50%;
      background: #0060a0;
      margin-right: 10px;
      text-align: center;
      padding-top: 5px; }
    .panel-group .panel-title-inner .name {
      padding-top: 15px; }
    .panel-group .panel-title-inner:before {
      content: none;
      width: 2px;
      height: 12px;
      display: block;
      background: white;
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -5px; }
    .panel-group .panel-title-inner:after {
      content: "";
      width: 12px;
      height: 2px;
      display: block;
      background: white;
      position: absolute;
      top: 50%;
      right: 10px; }
    .panel-group .panel-title-inner.collapsed:before {
      content: ""; }
  .panel-group .panel-body {
    margin: 0;
    padding: 0 0.625rem; }

html {
  font-size: 16px; }

.wrapper .main-section {
  max-width: 984px;
  margin-left: auto;
  margin-right: auto; }

.bgd-masthead {
  position: absolute;
  height: 7rem;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1; }

@media only screen and (min-width: 768px) {
  .title-application {
    font-size: 2.8125rem; }
    .title-application span {
      display: inline-block; }

  .bgd-masthead {
    height: 27.25rem; }

  .panel-group .panel-title-inner {
    padding: 20px 50px 20px 15px;
    font-size: 23px; }
    .panel-group .panel-title-inner .number {
      padding-top: 3px; }
    .panel-group .panel-title-inner:before {
      margin-top: -8px;
      right: 23px;
      height: 18px; }
    .panel-group .panel-title-inner:after {
      right: 15px;
      width: 18px; } }
.form-group .row {
  margin-left: -5px;
  margin-right: -5px; }
.form-group [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px; }

.bg-error, .form-group.has-error .group-element:before,
.form-group.has-error .input-group.has-error:before {
  background: url("/images/icon-error.png") no-repeat center;
  background-size: 15px 15px; }

.bg-success, .form-group.has-success .group-element:before,
.form-group.has-success .input-group.has-success:before {
  background: url("/images/icon-sucess.png") no-repeat center;
  background-size: 15px 15px; }

.radio-rype, .radio input[type=radio], .radio-btn-text input[type=radio] {
  opacity: 0;
  position: absolute;
  left: -9999px;
  z-index: 12;
  width: 30px;
  height: 30px;
  cursor: pointer; }
  .radio-rype:checked, .radio input[type=radio]:checked, .radio-btn-text input[type=radio]:checked, .radio-rype:focus, .radio input[type=radio]:focus, .radio-btn-text input[type=radio]:focus {
    outline: none; }

.button-style-2, .btn-secondary, .radio-btn-text input[type=radio] ~ .text, .custom-file-upload .btn-upload, .links .link-image, .links .link {
  padding: 0.625rem;
  font-family: "museo700";
  font-size: 1.0625rem;
  background: white;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: all .2s linear;
  width: 100%;
  text-transform: uppercase;
  border: 1px solid #0072a6;
  color: #0072a6; }

.btn-secondary {
  height: 1.875rem;
  margin-bottom: 1.875rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: white;
  display: block;
  line-height: 1.75rem;
  text-align: center; }
  .no-touch .btn-secondary:hover {
    background-color: #0072a6;
    color: white;
    text-decoration: none; }
  .btn-secondary.blue {
    background-color: #0072a6;
    color: white; }
    .btn-secondary.blue:hover {
      background-color: white;
      color: #0072a6; }
  .btn-secondary:active, .btn-secondary:active:focus, .btn-secondary:focus {
    background-color: white;
    color: #0072a6; }

/* Radio */
.radio,
.checkbox {
  margin-top: 0;
  margin-bottom: 0; }
  .radio label,
  .checkbox label {
    padding-left: 0; }

.radio input[type=radio]:checked ~ .text:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 8px;
  margin-top: -7px;
  display: inline-block;
  background-color: #0072ad;
  border-color: #dadada;
  width: 14px;
  height: 14px;
  border-radius: 100%; }
.radio input[type=radio] ~ .text {
  position: relative;
  z-index: 11;
  margin: 0;
  min-height: 30px;
  min-width: 30px;
  padding-left: 2.375rem;
  padding-top: 0.125rem;
  font-size: 1rem;
  line-height: 1.875rem;
  cursor: pointer;
  font-weight: bold; }
  .radio input[type=radio] ~ .text:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    border: 1px solid #dadada;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    line-height: 30px;
    background-color: white;
    border-radius: 100%; }

.radio-btn-text {
  width: 100%; }
  .radio-btn-text input[type=radio]:checked ~ .text {
    background-color: #0072a6;
    color: white; }
  .radio-btn-text input[type=radio] ~ .text {
    display: inline-block;
    position: relative;
    z-index: 11;
    margin: 0;
    min-height: 30px;
    padding: 0 0.625rem;
    font-size: 1rem;
    line-height: 1.875rem;
    cursor: pointer; }
  .radio-btn-text label {
    width: 100%;
    text-align: center;
    margin-bottom: 0; }
  .radio-btn-text.more-line input[type=radio] ~ .text {
    line-height: 1.25rem;
    padding: 0.625rem; }

.dropdown {
  position: relative;
  width: 100%; }
  .dropdown:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0.375rem;
    width: 0.5rem;
    height: 0.5rem;
    margin-top: -0.375rem;
    border: 1px solid #0072ad;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg); }
  .dropdown .form-control {
    padding-left: 1.25rem;
    color: #a9a9a9; }
    .dropdown .form-control.selected {
      color: #4d4d4f;
      line-height: 1.875rem;
      padding-top: 0;
      padding-bottom: 0; }
  .dropdown select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0; }

/* FORM */
.form fieldset {
  margin-bottom: 0.625rem; }
.form .btn-primary {
  margin-bottom: 1.875rem; }

.form-control.size-1,
.size-1 {
  width: 33.33%; }

.group-inner {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }
  .group-inner a {
    color: #4d4d4f;
    text-decoration: underline; }
    .group-inner a:hover {
      text-decoration: none; }
  .group-inner .btn-primary,
  .group-inner .btn-secondary {
    text-decoration: none; }

.btn-primary {
  display: block;
  text-align: center;
  padding: 0.5rem 0.625rem;
  font-family: "museo700";
  font-size: 1.0625rem;
  color: #4d4d4f;
  background: #ffc423;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: all .2s linear;
  border: 0;
  width: 100%;
  text-transform: uppercase; }
  .no-touch .btn-primary:hover, .no-touch .btn-primary:focus {
    color: white;
    background: #007dc3;
    text-decoration: none; }
  .touch .btn-primary:hover {
    background-color: #ffc423;
    border-color: #ffc423;
    color: #4d4d4f; }
  .btn-primary:active, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary:focus {
    background-color: #ffc423; }

.title {
  margin: 0.3125rem 0 0.625rem;
  font-size: 1rem;
  font-weight: bold; }

.form-group {
  margin-bottom: 1.875rem; }
  .form-group:before, .form-group:after {
    content: " ";
    display: table; }
  .form-group:after {
    clear: both; }
  .form-group .group-element,
  .form-group .input-group {
    position: relative; }
  .form-group.has-success .form-control:focus, .form-group.has-error .form-control:focus {
    box-shadow: none; }
  .form-group.has-success .group-element:before,
  .form-group.has-success .input-group:before, .form-group.has-error .group-element:before,
  .form-group.has-error .input-group:before {
    content: "";
    position: absolute;
    top: 3px;
    right: 0;
    width: 0.9375rem;
    height: 0.9375rem; }
  .form-group.has-success .form-control:focus {
    border-color: #dbdbdb; }
  .form-group.has-success .success-message {
    display: block; }
  .form-group.has-error .form-control:not(.optional-control),
  .form-group.has-error .input-group.has-error .optional-control {
    border-color: #fe4145; }
  .form-group.has-error .input-group.has-success .form-control {
    border-color: #dbdbdb; }
  .form-group.margin-small {
    margin-bottom: 0.625rem; }
  .form-group .input-group {
    width: 100%;
    display: inline-block;
    margin-bottom: 1.875rem; }
  .form-group .label-dropdown {
    margin-bottom: 0.1875rem;
    font-size: 1rem;
    font-weight: normal; }
  .form-group .form-control {
    width: 100%;
    padding: 0.3125rem;
    background-color: #f7f7f7;
    border: 1px solid #dbdbdb;
    box-shadow: none;
    height: 1.875rem;
    font-size: 0.9375rem; }
    .form-group .form-control::-moz-placeholder {
      color: #4d4d4f;
      opacity: 0.5; }
    .form-group .form-control:-ms-input-placeholder {
      color: #a9a9a9; }
    .form-group .form-control::-webkit-input-placeholder {
      color: #4d4d4f;
      opacity: 0.5; }
  .form-group .error-message,
  .form-group .success-message {
    width: 100%;
    margin-top: 0.3125rem;
    margin-bottom: 0;
    display: none;
    position: absolute;
    bottom: -1.25rem; }
  .form-group .error-message {
    color: #dc2052;
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .form-group .success-message,
  .form-group .text-card {
    font-style: italic;
    font-size: 1rem; }
  .form-group .text-card {
    margin-top: 0.625rem;
    font-weight: normal; }
  .form-group.success-style .success-message {
    position: static;
    margin-top: 1.125rem;
    width: 100%; }
  .form-group.success-style.has-success .group-element:before {
    top: 1.875rem; }

.form-group-multiple {
  margin-bottom: 0; }
  .form-group-multiple.has-success .group-element:before, .form-group-multiple.has-error .group-element:before {
    display: none; }

.text-card {
  font-style: italic;
  font-size: 0.9375rem; }

.list-unstyled {
  margin-bottom: 0; }

.group-credit {
  margin-bottom: 1.25rem;
  padding-right: 0; }
  .group-credit:before, .group-credit:after {
    content: " ";
    display: table; }
  .group-credit:after {
    clear: both; }
  .group-credit .radio,
  .group-credit .input-price {
    float: left; }
  .group-credit .radio .text {
    letter-spacing: -1px; }
  .group-credit .input-price {
    position: relative; }
    .group-credit .input-price .text {
      position: absolute;
      top: 0.3125rem;
      left: 0.75rem;
      font-size: 0.9375rem; }
    .group-credit .input-price .form-control {
      padding-left: 1.25rem;
      padding-top: 0.375rem;
      font-weight: bold;
      line-height: 1; }

.group-radio .radio .text {
  letter-spacing: -1px; }

.success-style .size-1 {
  position: relative;
  display: inline-block; }
.success-style .size-1,
.success-style .success-message {
  float: left; }
.success-style .success-message {
  position: relative;
  bottom: auto;
  width: 66%;
  margin-top: 1.25rem;
  padding-left: 0.625rem;
  text-overflow: inherit;
  white-space: normal;
  height: 43px;
  overflow: hidden; }

.active {
  display: block; }

.custom-file-upload .upload-input {
  display: none;
  visibility: hidden;
  position: absolute;
  left: -9999px; }
.custom-file-upload .content {
  position: relative;
  width: 68%;
  float: left; }
.custom-file-upload .btn-upload {
  width: 30%;
  float: right;
  padding: 0;
  height: 1.875rem;
  line-height: 1.875rem; }
  .custom-file-upload .btn-upload:hover {
    background: #0072a6;
    color: white; }
.custom-file-upload .icon-close {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 1.875rem;
  height: 1.75rem;
  font-size: 0.9375rem;
  line-height: 1.75rem;
  font-weight: bold;
  color: #4d4d4f;
  text-decoration: none;
  text-align: center;
  background-color: #f7f7f7; }
  .custom-file-upload .icon-close:hover {
    text-decoration: none; }

.checkbox {
  margin-bottom: 1.5rem; }
  .checkbox input[type="checkbox"] ~ .text {
    position: relative;
    display: inline-block;
    padding-left: 2.5rem;
    font-family: Arial;
    font-size: 1rem;
    font-weight: normal;
    color: #4d4d4f;
    cursor: pointer; }
    .checkbox input[type="checkbox"] ~ .text:before {
      content: '';
      width: 30px;
      height: 30px;
      border: 1px solid #dadada;
      position: absolute;
      left: 0;
      top: 0.1875rem; }
    .checkbox input[type="checkbox"] ~ .text a {
      text-decoration: underline;
      color: #4d4d4f; }
      .checkbox input[type="checkbox"] ~ .text a:hover {
        text-decoration: none; }
  .checkbox input[type="checkbox"]:checked ~ .text:before {
    background: url("/images/icon-checked.png") center center no-repeat; }

/* dl dt dd */
dl {
  font-family: Arial;
  overflow: hidden;
  margin-bottom: 0; }
  dl dt {
    width: 70%;
    float: left; }
    dl dt .title-1 {
      margin-bottom: 0.3125rem; }
    dl dt .text {
      font-weight: normal; }
  dl dd {
    float: right;
    width: 23%;
    padding-top: 0.5rem; }
    dl dd .link {
      float: left;
      padding-top: 5px;
      color: #007dc3;
      text-decoration: none; }
    dl dd .error-img {
      float: right;
      cursor: pointer; }

.result-list .form-group .group-element {
  font-family: arial;
  margin-bottom: 8px; }
  .result-list .form-group .group-element .title-return {
    font-size: 0.9375rem;
    font-weight: bold;
    margin-bottom: 2px;
    margin-top: 0; }
  .result-list .form-group .group-element p {
    font-size: 0.9375rem;
    font-weight: normal;
    margin-bottom: 0; }
  .result-list .form-group .group-element .btn-secondary {
    margin-top: 10px;
    margin-bottom: 0; }

.card-heading .group-button {
  margin-top: 1.5625rem; }

.text-block {
  margin-bottom: 1.125rem; }

.current-card {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 2.75rem;
  padding-bottom: 2.875rem; }
  .current-card .form-group {
    margin-bottom: 0; }

.form-group .width-default,
.form-group .width-small {
  float: left;
  padding-left: 0.625rem; }
  .form-group .width-default:first-child,
  .form-group .width-small:first-child {
    padding-left: 0; }
.form-group .width-default {
  width: 30%; }
.form-group .width-small {
  width: 40%; }
.form-group.pay-group .radio-btn-text {
  float: left; }
  .form-group.pay-group .radio-btn-text + .radio-btn-text {
    margin-left: 3.33333%; }
.form-group.pay-group .radio-btn-text:nth-child(1) {
  width: 23.33333%; }
.form-group.pay-group .radio-btn-text:nth-child(2) {
  width: 40%; }
.form-group.pay-group .radio-btn-text:nth-child(3) {
  width: 30%; }

.edit-vehicle-driver dt {
  width: 100%; }
.edit-vehicle-driver dd {
  width: 100%; }
.edit-vehicle-driver input[type='text'] {
  border: none;
  margin-bottom: 0.3125rem;
  background: none;
  float: left;
  padding: 0 0.3125rem;
  font-size: 0.9375rem; }
  .edit-vehicle-driver input[type='text'].first {
    border-right: 1px solid #4d4d4f; }

.links {
  padding-left: 0; }
  .links a {
    text-decoration: none;
    display: inline-block;
    text-align: center; }
  .links .link-image:hover, .links .link-image.active,
  .links .link:hover,
  .links .link.active {
    background-color: #0072a6;
    color: white; }
    .links .link-image:hover:before, .links .link-image.active:before,
    .links .link:hover:before,
    .links .link.active:before {
      color: white; }
  .links .link-image {
    width: 100%;
    position: relative;
    height: 5.9375rem;
    padding: 2.9375rem 0.375rem 0.375rem;
    font-size: 0.9375rem;
    font-weight: bold;
    font-family: arial;
    color: #4d4d4f;
    letter-spacing: -1px;
    line-height: 1.1;
    text-transform: none; }
    .links .link-image:before {
      font-family: 'svg-icon';
      font-size: 1.875rem;
      color: #0072a6;
      position: absolute;
      top: 0.625rem;
      left: 50%;
      margin-left: -0.9375rem; }
  .links .link {
    position: relative;
    z-index: 11;
    min-height: 30px;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0.625rem; }

@media only screen and (min-width: 768px) {
  .links .link-image {
    font-size: 1rem;
    letter-spacing: 0; }

  .group-inner {
    max-width: 46.625rem;
    margin: 0 auto; }
    .group-inner .group-button {
      padding-right: 2.375rem; }
    .group-inner fieldset {
      margin-bottom: 1.25rem; }
    .group-inner .group-vehicle-driver .row {
      padding-bottom: 1.875rem; }

  .form-group .title {
    margin-top: 0.1875rem; }
  .form-group .full-width {
    width: 33.33%; }
    .form-group .full-width + .input-group {
      padding-left: 0.625rem; }
  .form-group .group-element,
  .form-group .group-radio {
    padding-right: 2.375rem; }
    .form-group .group-element:before, .form-group .group-element:after,
    .form-group .group-radio:before,
    .form-group .group-radio:after {
      content: " ";
      display: table; }
    .form-group .group-element:after,
    .form-group .group-radio:after {
      clear: both; }
  .form-group.has-error .group-element:before, .form-group.has-success .group-element:before {
    width: 1.5625rem;
    height: 1.5625rem;
    background-size: 1.5rem; }
  .form-group .input-group {
    margin-bottom: 0; }
  .form-group.has-error .group-element:before {
    top: 50%;
    margin-top: -0.0625rem; }
  .form-group.has-success .group-element:before {
    top: 50%;
    margin-top: -0.0625rem; }

  .group-credit .input-price .text {
    letter-spacing: 0; }
  .group-credit .input-price .form-control {
    padding-top: 0.3125rem; }

  .form-group.has-error .input-group:before, .form-group.has-success .input-group:before {
    display: none; }

  .form-group-multiple {
    margin-bottom: 1.875rem; }
    .form-group-multiple.has-success .group-element:before, .form-group-multiple.has-error .group-element:before {
      display: block; }

  .edit-vehicle-driver dt {
    width: 70%; }
  .edit-vehicle-driver dd {
    width: 24%;
    padding-top: 1.5625rem; }
  .edit-vehicle-driver input[type='text'] {
    margin-bottom: 0;
    max-width: 33%; } }
.main-header {
  padding: 0.625rem 0.625rem 3.75rem; }
  .main-header .logo {
    float: left;
    max-width: 9.3125rem;
    margin-right: 0.625rem; }
    .main-header .logo img {
      max-width: 100%;
      display: block; }

@media only screen and (min-width: 768px) {
  .main-header {
    padding: 5rem 0 5.5rem; }
    .main-header .logo {
      max-width: 17rem; } }
.main-footer {
  margin-top: 2.1875rem;
  margin-bottom: 2.1875rem;
  padding: 0 0.9375rem; }
  .main-footer .logo-footer {
    float: left; }
  .main-footer .site-info,
  .main-footer .link-footer {
    margin-left: 4rem;
    margin-bottom: 0;
    font-size: 0.6875rem;
    color: #4d4d4f;
    font-family: Arial, Helvetica, sans-serif; }
    .main-footer .site-info a,
    .main-footer .link-footer a {
      color: #4d4d4f;
      display: inline-block;
      vertical-align: middle;
      padding: 0 0.25rem;
      border-left: 1px solid #636363;
      line-height: 0.75rem; }
      .main-footer .site-info a:first-child,
      .main-footer .link-footer a:first-child {
        padding-left: 0;
        border-left: 0; }
    .main-footer .site-info .text-phone,
    .main-footer .link-footer .text-phone {
      font-family: "museo500";
      font-size: 0.6875rem;
      line-height: 10px;
      color: #4d4d4f;
      vertical-align: middle;
      font-weight: normal;
      border-left: 1px solid #636363;
      padding-left: 4px; }

@media only screen and (min-width: 768px) {
  .main-footer {
    overflow: hidden;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    padding: 0 0.1875rem; }
    .main-footer .site-info,
    .main-footer .link-footer {
      margin-top: 0.875rem; }
    .main-footer .site-info {
      float: left;
      margin-left: 5px; }
    .main-footer .link-footer {
      float: right;
      margin-top: 11px; }
      .main-footer .link-footer .text-phone {
        float: right;
        color: #333333;
        margin-left: 16px;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.125rem;
        border-left: 0;
        padding-left: 0; } }

/*# sourceMappingURL=style-bootstrap.css.map */
